const Color = {
    BASE_WHITE: "#ffffff",
    SECOND_WHITE: "#F6F6F6",
    BASE_BLACK: "#2f2d2d",
    SECOND_BLACK: "#d9d9d9",
    PINK: "#fa4c84",
    THIRD_PINK: "#8f0734",
    SECOND_PINK: "#f379a0",
    DARK_RED: "#c02a2a",
    PURPLE: '#d7567c',
    LIGHT_PURPLE: '#e76d91',
  }
  export default Color;
  